import React from 'react';
import { Dialog, Divider, DialogTitle } from '@mui/material';
import { useMutation, gql } from '@apollo/client';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { DEFAULT_PAYMENT_METHODS } from '@legalsurf/common';

import ChargeForm from 'src/forms/ChargeForm';
import dayjs from 'dayjs';
import { useStudioId } from 'src/utils/hooks/useStudioId';

const CREATE_CHARGE = gql`
  mutation createCharge(
    $studioId: ID!
    $sale: ID!
    $amount: Float!
    $concept: String
    $date: DateTime!
    $method: CharginMethod!
  ) {
    createCharge(
      studioId: $studioId
      sale: $sale
      amount: $amount
      concept: $concept
      date: $date
      method: $method
    ) {
      id
    }
  }
`;
const UPDATE_CHARGE = gql`
  mutation updateCharge(
    $chargeId: ID!
    $studioId: ID!
    $concept: String
    $date: DateTime!
    $method: CharginMethod!
  ) {
    updateCharge(
      chargeId: $chargeId
      concept: $concept
      date: $date
      method: $method
      studioId: $studioId
    ) {
      id
      date
      method
      concept
    }
  }
`;

const GET_SALE = gql`
  query getSale($id: ID!) {
    sale(id: $id) {
      id
      date
      concept
      amount
      hours
      charged
      charges {
        id
        date
        method
        concept
        amount
      }
      entity {
        id
        type
        displayName
      }
      filecase {
        id
        title
      }
      responsible {
        id
        picture
        name
      }
      tags {
        id
        name
        studioId
      }
    }
  }
`;

const ChargeDialog = ({ sale = {}, charge, open, onClose, title, ...rest }) => {
  const { openSnackbar } = useSnackbar();
  const studioId = useStudioId();
  const [createCharge, { loading }] = useMutation(CREATE_CHARGE, {
    refetchQueries: [
      {
        query: GET_SALE,
        variables: {
          id: sale?.id,
        },
      },
    ],
  });
  const [updateCharge] = useMutation(UPDATE_CHARGE, {
    refetchQueries: [
      {
        query: GET_SALE,
        variables: {
          id: sale?.id,
        },
      },
    ],
  });

  const handleSubmit = async (values, formikBag) => {
    const changeMutation = charge ? updateCharge : createCharge;
    try {
      await changeMutation({
        variables: {
          studioId,
          amount: Number(values.amount),
          chargeId: charge ? charge.id : null,
          date: new Date(values.date).toISOString(),
          concept: values.concept,
          method: values.paymentMethod.value,
          sale: sale.id,
        },
      });

      openSnackbar('Cobro creado exitosamente.', {
        severity: 'success',
      });

      onClose();

      formikBag.resetForm({
        values: undefined,
      });
    } catch (error) {
      openSnackbar('Hubo un error. Intente mas tarde.', {
        severity: 'error',
      });
    }
  };

  return (
    <Dialog open={open} onClose={loading ? undefined : onClose} {...rest}>
      <DialogTitle>{title}</DialogTitle>

      <Divider />

      <ChargeForm
        buttonLabel={charge ? 'Editar' : 'Guardar'}
        initialValues={{
          ...sale,
          currency: sale.currency
            ? { label: sale.currency.name, value: sale.currency.name }
            : null,
          date: dayjs(charge?.date),
          saleTotal: sale?.amount,
          saleCharged: sale?.charged,
          amount: charge?.amount ? charge.amount : 0,
          concept: charge?.concept,
          paymentMethod:
            DEFAULT_PAYMENT_METHODS.find(
              (method) => method.value === charge?.method,
            ) || DEFAULT_PAYMENT_METHODS[2],
        }}
        isEdit={!!charge}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default ChargeDialog;
