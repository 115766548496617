import React from 'react';
import { Dialog } from '@mui/material';
import { useQuery } from '@apollo/client';

import { GET_SALE } from '../SalesQueries';
import { SaleDetailsContent } from './SaleDetailsContent';

const SaleDetailsDialog = ({ id, onClose, ...props }) => {
  const { data: { sale } = {}, loading } = useQuery(GET_SALE, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id,
    },
  });

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} {...props}>
      <SaleDetailsContent loading={loading} sale={sale} onClose={onClose} />
    </Dialog>
  );
};

export default SaleDetailsDialog;
