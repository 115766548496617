import React from 'react';
import {
  Avatar,
  Chip,
  Box,
  Typography,
  CircularProgress,
  DialogContent,
  Stack,
  IconButton,
  Tooltip,
  Divider,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { LSDocument, LSEdit, LSFilecase, LSX } from 'src/components/icons';

import { toDateFormat } from 'src/utils/dates';
import { formatMoney, stringAvatar } from 'src/utils/formatters';
import { useStudioId } from 'src/utils/hooks/useStudioId';

import { useDialog } from 'src/dialogs/Dialogs';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { Add } from '@mui/icons-material';
import {
  DEFAULT_PAYMENT_METHODS,
  LS_PERMISSIONS_MAP,
  entityTypesObj,
} from '@legalsurf/common';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { DELETE_CHARGE, GET_SALE } from '../SalesQueries';
import { SaleDetailsHeaderActions } from './SaleDetailsHeaderActions';

export const SaleDetailsContent = ({ sale, loading, onClose }) => {
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const dispatchChargeFormDialog = useDialog('chargeForm');
  const { openSnackbar } = useSnackbar();
  const studioId = useStudioId();

  const [deleteCharge, { loading: isDeletingCharge }] = useMutation(
    DELETE_CHARGE,
    {
      refetchQueries: [GET_SALE],
    },
  );

  const handleDeleteCharge = async (charge) => {
    try {
      await deleteCharge({
        variables: {
          studioId,
          id: charge.id,
        },
      });

      openSnackbar('Cobro eliminado con exito.', {
        severity: 'success',
      });
    } catch (error) {
      openSnackbar(error.message, { severity: 'error', autoDismiss: 200 });
    }
  };

  if (loading) {
    return <CircularProgress color="primary" size={26} />;
  }

  return (
    <DialogContent dividers>
      <Stack direction="row" justifyContent="space-between" pt={1}>
        <Stack direction="row" gap={1}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Chip
                color="success"
                label="Venta"
                size="small"
                variant="light"
              />

              <Typography sx={{ ml: 2, color: 'grey.400' }}>
                {toDateFormat(sale.date, 'DD/MM/YYYY')}
              </Typography>
            </Box>

            <Box sx={{ mt: 1 }}>
              {!!sale.tags &&
                sale.tags.map((tag) => (
                  <Chip
                    key={tag.name}
                    label={tag.name}
                    size="small"
                    sx={{
                      color: (theme) => theme.palette.primary.dark,
                      backgroundColor: (theme) => theme.palette.primary[100],
                    }}
                  />
                ))}

              {sale?.hours > 0 && (
                <Typography variant="h6">
                  {sale.hours} Horas contabilizadas
                </Typography>
              )}
            </Box>

            <Typography color="success.main" sx={{ my: 3 }} variant="h2">
              {sale.currency?.name} {formatMoney(sale.amount)}
            </Typography>

            <Typography variant="h4">{sale.concept}</Typography>

            {!!sale.filecase && (
              <Box sx={{ display: 'flex', alignItems: 'center', m: 2 }}>
                <LSFilecase color="primary" />

                <Typography sx={{ ml: 2, fontWeight: 400 }} variant="h5">
                  {sale.filecase.title}
                </Typography>
              </Box>
            )}

            {!!sale.responsible && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ mr: 2, fontWeight: 400, m: 2 }} variant="h6">
                  Responsable:
                </Typography>
                <Avatar
                  {...stringAvatar(sale.responsible?.name, {
                    width: 25,
                    height: 25,
                    fontSize: 12,
                  })}
                />
                <Typography fontWeight={300} ml={2}>
                  {sale.responsible?.name}
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                m: 2,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                '& > * + *': {
                  ml: 2,
                },
              }}
              onClick={() =>
                window.open(
                  `/dashboard/${studioId}/directories/${sale.entity.id}`,
                  '_blank',
                )
              }
            >
              <Avatar
                {...stringAvatar(sale.entity.displayName, {
                  width: 25,
                  height: 25,
                  fontSize: 12,
                })}
              />

              <Chip
                color="primary"
                label={entityTypesObj[sale.entity.type]}
                size="small"
                variant="light"
              />

              <Typography fontWeight={300}>
                {sale.entity.displayName}
              </Typography>
            </Box>
          </Box>
        </Stack>

        <Stack direction="column" gap={1} justifyContent="flex-start">
          <SaleDetailsHeaderActions
            disabled={loading}
            filecase={sale?.filecase}
            sale={sale}
            onClose={onClose}
          />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <CircularProgress
                size={60}
                sx={{
                  mr: 2,
                  color: 'grey.100',
                }}
                thickness={7}
                value={100}
                variant="determinate"
              />
              <CircularProgress
                color="primary"
                size={60}
                sx={{
                  color: 'primary',
                  position: 'absolute',
                  left: 0,
                }}
                thickness={7}
                value={(sale.charged * 100) / sale.amount}
                variant="determinate"
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>Total cobrado</Typography>
              <Typography color="primary" variant="h4">
                {formatMoney(sale.charged)}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Stack>

      {!!sale.charges?.length && (
        <>
          <Divider />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Box
              component="ul"
              sx={{
                padding: 0,
                maxHeight: 180,
                overflowY: 'auto',
                '& > * + *': {
                  marginTop: 4,
                },
              }}
            >
              {sale.charges?.map((charge) => (
                <Box
                  key={charge.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                  }}
                >
                  <Typography fontWeight={400}>
                    {toDateFormat(charge.date)}
                  </Typography>

                  <Chip color="primary" label="Cobro" value="Cobro" />

                  <Typography sx={{ width: 150, fontWeight: 400 }} variant="h6">
                    {charge.amount}
                  </Typography>

                  <Typography
                    noWrap
                    sx={{ width: 150, flex: 1, fontWeight: 400 }}
                    variant="h6"
                  >
                    {charge.concept}
                  </Typography>

                  <Chip
                    color="primary"
                    label={
                      DEFAULT_PAYMENT_METHODS.find(
                        (method) => method.value === charge.method,
                      ).label
                    }
                    sx={{ mr: 2 }}
                    value={
                      DEFAULT_PAYMENT_METHODS.find(
                        (method) => method.value === charge.method,
                      ).label
                    }
                    variant="light"
                  />

                  <Box>
                    <PermissionGuard
                      permissions={LS_PERMISSIONS_MAP.SALES.DELETE}
                    >
                      <Tooltip title="Eliminar cobro">
                        <IconButton
                          disabled={isDeletingCharge}
                          onClick={() =>
                            dispatchWarningPromptDialog(charge.id, {
                              onAccept: () => handleDeleteCharge(charge),
                            })
                          }
                        >
                          <LSX />
                        </IconButton>
                      </Tooltip>
                    </PermissionGuard>

                    <PermissionGuard
                      permissions={LS_PERMISSIONS_MAP.SALES.UPDATE}
                    >
                      <Tooltip title="Editar ese cobro">
                        <IconButton
                          disabled={isDeletingCharge}
                          onClick={() =>
                            dispatchChargeFormDialog(charge.id, {
                              sale,
                              charge,
                            })
                          }
                        >
                          <LSEdit />
                        </IconButton>
                      </Tooltip>
                    </PermissionGuard>

                    <Tooltip title="Descargar Comprobante">
                      <IconButton
                        disabled={isDeletingCharge}
                        onClick={() =>
                          window.open(
                            `/dashboard/${studioId}/charge/${charge.id}/voucher`,
                            '_blank',
                          )
                        }
                      >
                        <LSDocument />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </>
      )}

      {sale.charged < sale.amount && (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={1}
          sx={{
            backgroundColor: 'primary.100',
            borderRadius: 1,
            p: 3,
            borderStyle: 'dashed',
            borderWidth: 1,
            mt: 1,
            mb: 2,
            borderColor: 'primary.500',
            cursor: 'pointer',
            // transition: 'all 100ms linear',
            // ':hover': {
            //   borderColor: 'primary.main',
            //   backgroundColor: 'primary.300',
            // },
          }}
          onClick={() =>
            dispatchChargeFormDialog('ChargeDetailsForm Dialog', {
              sale,
            })
          }
        >
          <Add color="primary" fontSize="small" />

          <Typography color="primary.main" variant="caption">
            Cargar cobro a tus honorarios
          </Typography>
        </Stack>
      )}
    </DialogContent>
  );
};
