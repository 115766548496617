import React, { useCallback } from 'react';
import { Box, Button, Stack } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { Download } from '@mui/icons-material';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

import { useDialog } from 'src/dialogs/Dialogs';
import SectionHeader, {
  SectionHeaderActiveFilters,
  SectionHeaderHeading,
} from 'src/components/v3/SectionHeader';
import {
  CurrencyAutocomplete,
  EntitiesAutocomplete,
  FilecasesAutocomplete,
  StudioMembersAutocomplete,
  TagsAutocomplete,
} from 'src/autocompletes';
import { ArrayParam } from 'use-query-params';
import { createHeaderFiltersStore } from 'src/components/v3/SectionHeader/utils';
import { LSAdd, LSTrash } from 'src/components/icons';
import SectionHeaderAdvanceFilters from 'src/components/v3/SectionHeader/SectionHeaderAdvanceFilters';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import MoreButton from 'src/components/v2/MoreButton';
import { useFilters } from 'src/components/v3/SectionHeader/useFilters';
import { useMutation } from '@apollo/client';
import { DELETE_SALE } from 'src/pages/Dashboard/Sales/SalesQueries';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { useStudioId } from 'src/utils/hooks/useStudioId';

export const SalesTableSearchFiltersId = 'SalesTableSearchFilters-v2';

const paramConfigMap = {
  filecases: ArrayParam,
  entities: ArrayParam,
  responsibles: ArrayParam,
  currencies: ArrayParam,
  tags: ArrayParam,
};

export const salesTableHeaderDefaultInitialValues = {
  filecases: [],
  entities: [],
  responsibles: [],
  currencies: [],
  tags: [],
};

const useEntitiesFiltersStore = createHeaderFiltersStore(
  SalesTableSearchFiltersId,
  salesTableHeaderDefaultInitialValues,
);

export const useSalesFilters = () => {
  return useFilters(
    useEntitiesFiltersStore,
    paramConfigMap,
    salesTableHeaderDefaultInitialValues,
  );
};

const Header = ({
  title,
  subtitle,
  loading,
  totalItems,
  selectedItems,
  onDownloasdAsExcel: handleDownloadAsExcel,
}) => {
  const studioId = useStudioId();
  const { filters, setFilters, resetFilters, initialValues } =
    useSalesFilters();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');

  const { openSnackbar } = useSnackbar();

  const dispatchSalesFormDialog = useDialog('salesForm');

  const handleFiltersChange = useCallback(
    (values) => Promise.resolve(setFilters(values)),
    [setFilters],
  );

  const handleReset = useCallback(
    (formikBag) => {
      resetFilters();
      formikBag.setValues(salesTableHeaderDefaultInitialValues);
    },
    [setFilters, resetFilters],
  );

  const [deleteSale, { loading: isDeletingSale }] = useMutation(DELETE_SALE);

  const handleDelete = async () => {
    try {
      const plural = selectedItems.length ? 'Gastos' : 'Gasto';

      const promises = selectedItems.map((id) =>
        deleteSale({
          variables: {
            id,
            studioId,
          },
          update: (cache) => {
            const normalizedId = cache.identify({
              id,
              __typename: 'Sale',
            });

            cache.evict({ id: normalizedId });
            cache.gc();
          },
        }),
      );

      await Promise.allSettled(promises);

      openSnackbar(`${plural} eliminado exitosamente.`, {
        severity: 'success',
      });
    } catch (error) {
      Sentry.captureException(error);
      openSnackbar(
        'Hubo un problema al eliminar el/los gastos. Intente mas tarde.',
        {
          severity: 'error',
        },
      );
    }
  };

  return (
    <SectionHeader
      filters={initialValues}
      loading={loading}
      onChange={handleFiltersChange}
      onSubmit={handleFiltersChange}
    >
      {() => (
        <>
          <Stack alignItems="center" direction="row" gap={2}>
            <Stack direction="column">
              <SectionHeaderHeading variant="h3">{title}</SectionHeaderHeading>

              {subtitle && (
                <SectionHeaderHeading variant="subtitle1">
                  {subtitle}
                </SectionHeaderHeading>
              )}
            </Stack>

            <SectionHeaderAdvanceFilters onReset={handleReset}>
              <FilecasesAutocomplete
                multiple
                name="filecases"
                size="small"
                label="Expedientes"
              />

              <StudioMembersAutocomplete
                multiple
                name="responsibles"
                size="small"
                label="Responsables"
              />

              <EntitiesAutocomplete
                multiple
                name="entities"
                size="small"
                label="Directorios"
              />

              <TagsAutocomplete
                multiple
                label="Etiquetas"
                name="tags"
                size="small"
              />

              <CurrencyAutocomplete
                multiple
                label="Monedas"
                name="currencies"
                size="small"
              />
            </SectionHeaderAdvanceFilters>

            <Box
              sx={{
                ml: 'auto',
                gap: 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PermissionGuard
                permissions={[LS_PERMISSIONS_MAP.DIRECTORY.CREATE]}
              >
                <Button
                  color="primary"
                  startIcon={<LSAdd color="white" />}
                  variant="contained"
                  onClick={() => dispatchSalesFormDialog('SalesTableForm')}
                >
                  Crear Honorario
                </Button>
              </PermissionGuard>

              <MoreButton popperHeader="Acciones">
                <Stack gap={0.5} p={1}>
                  <PermissionGuard
                    permissions={[LS_PERMISSIONS_MAP.SALES.DELETE]}
                  >
                    <Button
                      disabled={!selectedItems.length || isDeletingSale}
                      size="large"
                      startIcon={<LSTrash />}
                      sx={{ justifyContent: 'flex-start' }}
                      variant="text"
                      onClick={() =>
                        dispatchWarningPromptDialog('ExpensesTableHeader', {
                          onAccept: handleDelete,
                          title: 'Eliminar gastos',
                        })
                      }
                    >
                      Eliminar
                    </Button>
                  </PermissionGuard>

                  <PermissionGuard
                    permissions={[LS_PERMISSIONS_MAP.DIRECTORY.ACCESS]}
                  >
                    <Button
                      size="large"
                      startIcon={<Download />}
                      variant="text"
                      onClick={handleDownloadAsExcel}
                    >
                      Descargar Excel ({selectedItems?.length || totalItems})
                    </Button>
                  </PermissionGuard>
                </Stack>
              </MoreButton>
            </Box>
          </Stack>

          <SectionHeaderActiveFilters
            filterLabels={{
              filecases: 'Expedientes',
              entities: 'Directorios',
              responsibles: 'Responsables',
              tags: 'Etiquetas',
              currencies: 'Monedas',
            }}
            filters={filters}
          />
        </>
      )}
    </SectionHeader>
  );
};

export default Header;
