import React from 'react';
import dayjs from 'dayjs';
import {
  FileCopy,
  PictureAsPdf,
  Article,
  Image,
  ListAlt,
  Folder,
  AudioFile,
} from '@mui/icons-material';
import theme from 'src/config/mui-theme';

import { entityTypes } from '@legalsurf/common';
import { palette } from 'src/config/mui-theme/foundations';
import { fileTypes } from 'src/config/file-types';

export const categorizeFileExtension = ({ extension, isFolder }) => {
  if (isFolder) {
    return 'folder';
  }

  if (!extension) {
    return 'other';
  }

  const extensionWithoutFirstDot = (
    extension[0] === '.' ? extension.substr(1) : extension
  ).toLowerCase();

  switch (extensionWithoutFirstDot) {
    case 'text':
    case 'doc':
    case 'docm':
    case 'docx':
    case 'oth':
    case 'rtf':
    case 'rtf2':
      return 'document';

    case 'pdf':
      return 'pdf';

    case 'csv':
    case 'xlsb':
    case 'xls':
    case 'xlsx':
    case 'xlsm':
    case 'xlx':
    case 'ods':
      return 'excel';

    case 'ppt':
    case 'ppsx':
    case 'ppsm':
    case 'pptm':
    case 'potm':
    case 'potx':
    case 'odp':
    case 'pptx':
      return 'powerpoint';

    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'svg':
      return 'image';

    case 'mp3':
    case 'mp4':
    case 'ogg':
      return 'audio';

    default:
      return 'other';
  }
};

export const getDiffDays = (date) => {
  const diffDays = dayjs(date).diff(Date.now(), 'days');

  if (diffDays >= 100) {
    return '100+';
  }

  return Math.abs(diffDays).toFixed();
};

const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export function removeNullKeys(obj = {}) {
  return Object.entries(obj).reduce((acc, [key, val]) => {
    if (!val || isEmpty(val)) {
      return acc;
    }

    return { ...acc, [key]: val };
  }, {});
}

// Me zarpe aca... copiando y pegando, tas' cla' 😎
export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name, sx = {}) => {
  const [nameLetter, ...rest] = name?.split(' ') ?? '';

  if (!name || !nameLetter) return '';

  return {
    sx: {
      // Nico: "Modo mvp chicos..."
      ...sx,
      bgcolor: stringToColor(name),
    },
    children: `${nameLetter[0]}${
      (nameLetter[1] ?? rest?.[0]?.[0]) || ''
    }`.toUpperCase(),
  };
};

export const getStateColor = (state) => {
  if (state?.color) {
    return state.color;
  }

  return palette.grey[100];
};

export const getEntityType = (type) => {
  const entity = entityTypes.find((x) => x.value === type);

  if (!entity) {
    throw Error(`${entity} entity type is not a valid type.`);
  }

  return entity.label;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${(bytes / k ** i).toFixed(dm)} ${sizes[i]}`;
};

export const getFileExtension = (filename) => {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? '' : ext[1];
};

const getFileTypeByMimeType = (file) => {
  const [documentTypeByMimeType] =
    Object.entries(fileTypes).find(
      ([, val]) => val === (file.mimeType ?? file.mimetype),
    ) ?? [];

  return documentTypeByMimeType;
};

export const measureFileIconBackground = (document) => {
  let type = categorizeFileExtension(document);

  if (!type || type === 'other') {
    const typeByMimeType = getFileTypeByMimeType(document);

    if (typeByMimeType) {
      type = typeByMimeType;
    }
  }

  switch (type) {
    case 'folder':
      return theme.palette.icons.folders;

    case 'pdf':
      return theme.palette.icons.pdf;

    case 'excel':
    case 'spreadsheet':
      return theme.palette.icons.excel;

    case 'powerpoint':
      return theme.palette.icons.powerpoint;

    case 'image':
      return theme.palette.icons.powerpoint;

    case 'document':
    case 'txt':
      return theme.palette.icons.document;

    case 'audio':
      return theme.palette.icons.audio;

    default:
      return theme.palette.icons.default;
  }
};

export const measureFileIcon = (document = {}) => {
  let type = categorizeFileExtension(document);

  if (type === 'other') {
    const typeByMimeType = getFileTypeByMimeType(document);

    if (typeByMimeType) {
      type = typeByMimeType;
    }
  }

  switch (type) {
    case 'folder':
      return () => <Folder color="primary" />;
    case 'pdf':
      return PictureAsPdf;
    case 'document':
      return Article;
    case 'image':
      return Image;
    case 'excel':
    case 'spreadsheet':
      return ListAlt;
    case 'audio':
      return AudioFile;

    default:
      return FileCopy;
  }
};

export const humanizedCategories = {
  excel: 'Hoja de cálculo',
  pdf: 'PDF',
  image: 'Imágenes',
  document: 'Documentos',
  folder: 'Carpeta',
  audio: 'Audio',
  other: 'Otro',
};

export const humanizeFileCategory = (category) => humanizedCategories[category];

export const formatMoney = (amount) => {
  if (!amount) {
    return 0;
  }

  const locale = window.navigator.language || 'es-MX'; // Fallback to a default locale if navigator.language is not available

  // Format the amount with commas as thousand separators and a period as the decimal separator
  return amount.toLocaleString(locale, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
