import React from 'react';
import { create } from 'zustand';

import SaleDetailsDialog from 'src/pages/Dashboard/Sales/SaleDetailsDialog';
import { useStudioId } from 'src/utils/hooks/useStudioId';

import ExpenseDetailsDialog from 'src/pages/Dashboard/Expenses/ExpenseDetailsDialog';
import FilePreviewer from 'src/components/v2/FilePreviewer';
import { usePostHog } from 'posthog-js/react';
import CalendarEventDetailsDialog from '../calendar/CalendarEventDetailsDialog';
import ChargeDialog from '../ChargeDialog';
import { EntityFormDialog } from '../EntityFormDialog';
import FilecaseDetailsDialog from '../FilecaseDetailsDialog';
import FilecaseFormDialog from '../FilecaseFormDialog';
import TransactionDialog from '../TransactionDialog';
import WarningDialog from '../WarningDialog';
import { EventFormDialog } from '../calendar/EventFormDialog';
import NotificationTemplateFormDialog from '../NotificationTemplateFormDialog';
import PaymentDialog from '../PaymentDialog';
import ExpenseDialog from '../ExpenseDialog';
import { ContractPreviewDialog } from '../ContractPreviewDialog';
import { CustomFieldFormDialog } from '../CustomFieldFormDialog';
import { WorkflowFormDialog } from '../WorkflowFormDialog';
import { WorkflowTimelineItemEventDialogForm } from '../WorkflowFormDialog/WorkflowTimelineContent/components/WorkflowTimelineItemEvent/WorkflowTimelineItemEventDialogForm';
import { EntityDialog } from '../EntityDialog';
import FilesFormDialog from 'src/dialogs/FilesFormDialog';

const DialogComponents = {
  filecase: FilecaseDetailsDialog,
  filecaseForm: FilecaseFormDialog,
  eventForm: EventFormDialog,
  event: CalendarEventDetailsDialog,
  entity: EntityDialog,
  documentsForm: FilesFormDialog,
  entityForm: EntityFormDialog,
  documentsPreview: FilePreviewer,
  salesForm: TransactionDialog,
  sale: SaleDetailsDialog,
  chargeForm: ChargeDialog,
  warningPrompt: WarningDialog,
  expense: ExpenseDetailsDialog,
  expenseForm: ExpenseDialog,
  expensesPaymentForm: PaymentDialog,
  contractPreview: ContractPreviewDialog,
  customFieldForm: CustomFieldFormDialog,
  workflowsForm: WorkflowFormDialog,
  workflowsEventForm: WorkflowTimelineItemEventDialogForm,
  notificationTemplateFormDialog: NotificationTemplateFormDialog,
};

const useDialogStore = create((set) => ({
  dialogs: {},
  setDialog: (dialog, key, data, open) =>
    set((state) => ({
      dialogs: {
        ...state.dialogs,
        [dialog]: {
          ...state.dialogs[dialog],
          [key]: { open, key, dialogType: dialog, data: data ?? null },
        },
      },
    })),
}));

// https://github.com/pmndrs/zustand/issues/934
export const zustandHmrFix = (name, useStore) => {
  if (import.meta.hot) {
    const state = import.meta.hot.data[name];

    if (state) {
      useStore.setState(import.meta.hot.data[name]);
    }

    useStore.subscribe((state) => {
      import.meta.hot.data[name] = state;
    });

    import.meta.hot.accept((newModule) => {
      if (newModule) {
        useStore.setState(import.meta.hot.data[name]);
      }
    });
  }
};

zustandHmrFix('dialogs store', useDialogStore);

export const useDialog = (dialogType) => {
  const studioId = useStudioId();
  const posthog = usePostHog();
  const setDialog = useDialogStore((state) => state.setDialog);

  return React.useCallback(
    (key, data) => {
      setDialog(dialogType, key, data ?? null, true);

      posthog.capture('DialogView', { key, dialog: dialogType, studioId });
    },
    [dialogType, setDialog, posthog, studioId],
  );
};

export const DialogsProvider = () => {
  const { dialogs, setDialog } = useDialogStore();

  const handleDialogClose = (dialog, key) =>
    setDialog(dialog, key, dialogs[dialog][key]?.data, false);

  const components = Object.values(dialogs)
    .map((dialogList) => Object.values(dialogList))
    .flat()
    .map((dialogData) => {
      const DialogComponent = DialogComponents[dialogData.dialogType];

      return (
        <DialogComponent
          {...dialogData.data}
          key={dialogData.key}
          open={dialogData.open}
          onClose={() =>
            handleDialogClose(dialogData.dialogType, dialogData.key)
          }
        />
      );
    });

  return components;
};
